<template>
  <ai-field v-bind="props" class="AiFieldSelect">
    <select
      :id="name"
      ref="input"
      v-model="value"
      :name="name"
      :disabled="disabled"
      class="AiFieldSelect-input"
      :class="{ 'AiFieldSelect-input--placeholder': isEmpty }"
      :aria-invalid="Boolean(errors)"
      :aria-label="label">
      <option v-if="placeholder" :value="null" disabled>
        {{ placeholder }}
      </option>
      <option
        v-for="option of options"
        :key="option.key ?? (option.value as string)"
        :value="option.value"
        :disabled="option.disabled"
        :aria-label="optionAriaLabel(option)">
        {{ option.label }}
      </option>
    </select>

    <!-- Slots forwarding -->
    <template v-if="$slots.helper" #helper>
      <slot name="helper" />
    </template>

    <template v-if="$slots.left" #left>
      <slot name="left" />
    </template>

    <template #right>
      <ai-icon :size="18" name="chevron-down" class="AiFieldSelect-icon" />
    </template>
  </ai-field>
</template>

<script setup lang="ts">
import { useCustomSelectAccessibility } from '../../hooks';

import type { AiInputOption } from './interfaces';
import AiField from './AiField.vue';

export type Props = {
  options?: AiInputOption[];
  placeholder?: string;
  customAccessibility?: boolean;
  // Field props
  modelValue?: string | number | boolean | Record<string, unknown> | null;
  label?: string;
  optionLabel?: string;
  name?: string;
  errors?: string[];
  successes?: string[];
  disabled?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
};

const props = withDefaults(defineProps<Props>(), {
  errors: undefined,
  helper: undefined,
  label: undefined,
  optionLabel: undefined,
  modelValue: null,
  name: undefined,
  options: () => [],
  placeholder: undefined,
  successes: undefined,
  type: 'text',
});

const emits = defineEmits<Emits>();

const input = ref<HTMLSelectElement>();

defineExpose({ input });

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: Props['modelValue']) {
    emits('update:modelValue', newValue);
  },
});

const isEmpty = computed(() => {
  return (
    (typeof value.value === 'string' && !value.value) ||
    typeof value.value === 'undefined' ||
    value.value === null
  );
});

const optionAriaLabel = (option: AiInputOption | undefined) => {
  return props.optionLabel && option
    ? `${props.optionLabel} - ${option.label}`
    : undefined;
};

useCustomSelectAccessibility(input, {
  active: props.customAccessibility,
  items: props.options,
  modelValue: value,
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/fonts';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/functions' as func;

.AiFieldSelect {
  position: relative;
}

.AiFieldSelect-input {
  appearance: none;
  width: 100%;
  flex-grow: 1;
  display: block;
  background-color: transparent;
  border: none;
  padding-right: func.calcRem(32);
  margin-right: func.calcRem(-32);

  @include fonts.paragraph-04-bold;

  &:focus-visible {
    outline: none;
  }
}

.AiFieldSelect-icon {
  pointer-events: none;
}

.AiFieldSelect-input--placeholder {
  @include fonts.paragraph-04-regular;
  color: colors.$stratos-700;
}

.AiFieldSelect-placeholder {
  position: absolute;
  left: constants.$inner-02;
  right: constants.$inner-02;
  z-index: -1;
}
</style>
